<template>
<template v-if="stats.localization">
    <div class="flex justify-start">
        <n-button
            secondary
            class="rounded-3xl"
            @click="router.go(-1)">
            <n-icon
                size="14"
                class="text-gray-600 dark:text-white/75 mr-2">
                <ArrowLeft12Regular />
            </n-icon>
            {{ t('back') }}
        </n-button>
    </div>
    <section class="flex mt-4 items-stretch">
        <div class="w-8/12 pr-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #header>
                    <div class="flex items-center pt-1 text-gray-600 dark:text-white/75 text-left">
                        <div>
                            {{ stats.localization['trade_statistics_management_botname'] }}
                            <strong class="text-main font-bold text-lg">{{ botShortInfo?.bot_name }}</strong>
                        </div>
                        <div class="ml-6">
                            {{ stats.localization['trade_statistics_management_botstatus'] }}
                            <strong class="text-main font-bold text-lg">{{ botShortInfo?.status_txt }}</strong>
                        </div>
                        <div class="ml-6">
                            {{ stats.localization['trade_statistics_management_botstate'] }}
                            <strong class="text-main font-bold text-lg">{{ botShortInfo?.state_txt }}</strong>
                        </div>
                    </div>
                </template>
                <template #default>
                    <div class="flex items-center justify-start text-gray-600 dark:text-white/75">
                        <span>{{ stats.localization['trade_statistics_management_current'] }}</span>
                        <span class="mx-4 rb-td-stock-icon w-12" :class="`rb-td-stock-icon-${exchange(botShortInfo?.exchange)?.code_name}`"></span>
                        <span>{{ stats.localization['trade_statistics_management_tickers_for'] }}</span>
                        <div class="ml-4 flex items-center">
                            <rb-coin class="w-6" :coin="currency(botShortInfo?.tickers_for?.split('/').shift().trim())" />
                            <div class="ml-2">{{ botShortInfo?.tickers_for }}</div>
                        </div>
                        <span class="ml-4">{{ stats.localization['trade_statistics_management_currenttickers_buy'] }}</span>
                        <strong class="text-main font-bold text-lg">&nbsp;{{ botShortInfo?.buy }}</strong>
                        <span class="ml-4">{{ stats.localization['trade_statistics_management_currenttickers_sell'] }}</span>
                        <strong class="text-main font-bold text-lg">&nbsp;{{ botShortInfo?.sell }}</strong>

                        <n-button
                            strong
                            class="ml-4 text-white/90 rounded-md"
                            :color="gl.mainColor"
                            :disabled="totalUpdateLoading"
                            :loading="totalUpdateLoading"
                            @click="totalUpdate">
                            {{ stats.localization['trade_statistics_bots_table_data_auto_refresh_button'] }}
                        </n-button>
                    </div>
                    <n-divider class="mt-4" />

                    <div class="flex flex-wrap">
                        <n-icon
                            v-if="botShortInfo?.exit_error"
                            class="text-red-500 cursor-pointer text-4xl mr-6"
                            @click="gl.showNotification({ type: 'error', msg: botShortInfo?.exit_error });">
                            <ErrorCircle20Regular />
                        </n-icon>
                        <template v-for="item in buttons" :key="item.label">
                            <n-button
                                v-if="item.show"
                                class="mr-3 mb-3 text-white/90 rounded-md"
                                :color="gl.mainColor"
                                :loading="item.loading"
                                :disabled="item.loading"
                                @click="item.fn">
                                {{ item.label }}
                            </n-button>
                        </template>

                        <reset-fix-order
                            :botShortInfo="botShortInfo"
                            :reSetFixOrder="reSetFixOrder"
                            @getData="getAll" />
                        
                        <insurance-order
                            v-if="!botShortInfo?.simulate"
                            :botShortInfo="botShortInfo" />
                    </div>
                </template>
            </n-card>
        </div>
        <div class="w-4/12 pl-2">
            <n-card
                size="small"
                :bordered="false"
                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg h-full"
                content-style="padding: 0;"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <template #header>
                    <div v-if="dashProfit.title" class="flex justify-center text-sm text-gray-600 dark:text-white/75 font-semibold">
                        <n-skeleton v-if="loading" :width="215" :height="20" :sharp="false" />
                        <template v-else>{{ dashProfit.title }}</template>
                    </div>
                </template>
                <div class="flex flex-col h-[306px]">
                    <div v-if="loading" class="h-[80%]">
                        <div
                            v-for="(n, i) in 6"
                            :key="i"
                            class="w-full dark:odd:bg-[#242424] odd:bg-gray-50 h-[40px]">
                        </div>
                    </div>
                    <n-scrollbar v-else class="h-[80%]">
                    <div
                        v-for="(stat, i) in dashProfit.stats"
                        :key="i"
                        class="w-full dark:odd:bg-[#242424] odd:bg-gray-50">
                        <div class="py-1 flex flex-row justify-start items-center overflow-x-hidden relative">
                            <div class="w-4/12 flex items-center">
                                <rb-coin class="mx-2 w-6" :coin="currency(stat[2])" />
                                <div class="text-sm font-semibold uppercase">{{ stat[2] }}</div>
                            </div>
                            
                            <div class="text-sm font-semibold" :class="stat[0] > 0 ? 'text-green-600' : 'text-red-500'">{{ stat[0] }}</div>

                            <div
                                v-if="stat[2] !== env.profitSymbol"
                                class="flex flex-row opacity-60 hover:opacity-100 items-center text-white justify-start absolute w-4/5 h-full bg-main right-[-72%] rounded-l-xl hover:right-0 transition-all cursor-pointer">
                                <n-icon class="absolute left-2 top-1/2 -translate-y-1/2">
                                    <ChevronLeft24Filled />
                                </n-icon>

                                <div class="w-3/12 flex items-center ml-6">
                                    <rb-coin class="mx-2 w-6" :coin="currency(env.profitSymbol)" />
                                    <div class="text-sm font-semibold uppercase">{{ env.profitSymbol }}</div>
                                </div>
                                
                                <div class="text-sm font-semibold">{{ stat[1] }}</div>
                            </div>
                        </div>
                    </div>
                    </n-scrollbar>
                    <div class="h-[20%] flex flex-col justify-center items-center">
                        <n-skeleton v-if="loading" :width="200" :height="18" :sharp="false" />
                        <div v-else class="text-sm font-semibold">{{ dashProfit.data?.btc }} {{ env.profitSymbol.toUpperCase() }} / {{dashProfit.data?.usdt || '---'}} USDT</div>

                        <n-skeleton v-if="loading" class="mt-2" :width="130" :height="16" :sharp="false" />
                        <div v-else class="text-sm">( {{ dashProfit.ciclos }} {{ stats.localization['dashboard_profit_total_cycles_completed'] }} )</div>
                    </div>
                </div>
            </n-card>
        </div>
    </section>

    <super-table
        v-if="init"
        showSearch
        class="mt-4"
        :filters="filters"
        :refFilters="refFilters"
        :pager="stats.cyclesDataTable.pager"
        :columns="columns"
        :loading="loadings.table"
        :records="stats.cyclesDataTable.records"
        :filtersChanged="filtersChanged"
        columnsStorageName="__columns_cycles_table"
        type="cycles_table"
        @getData="getAll(false)"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @reset-filters="resetFilters"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
                {{ stats.localization['trade_statistics_management_cyclesinfo'] }}
            </div>
        </template>
        <template #stats>
            <n-card
                size="small"
                :bordered="false"
                class="shadow bg-white dark:bg-darkCardBg rounded-lg my-3"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <div class="text-md text-gray-600 dark:text-white/75">
                    <span>{{ stats.localization['trade_statistics_management_cyclesinfo_totalcycles'] }}</span>
                    <n-tag round size="small" :bordered="false" type="warning" class="ml-2">
                        <span class="text-yellow-500 font-semibold">{{ stats.cyclesDataTable.stats.total }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_management_ordershistory_running'] }}</span>
                    <n-tag round size="small" :bordered="false" type="success" class="ml-2">
                        <span class="text-green-500 font-semibold">{{ stats.cyclesDataTable.stats.running }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_management_ordershistory_canceled'] }}</span>
                    <n-tag round size="small" :bordered="false" type="error" class="ml-2">
                        <span class="font-semibold">{{ stats.cyclesDataTable.stats.canceled }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_management_cyclesinfo_completed'] }}</span>
                    <n-tag round size="small" :bordered="false" class="ml-2">
                        <span class="font-semibold">{{ stats.cyclesDataTable.stats.completed }}</span>
                    </n-tag>
                </div>
            </n-card>
        </template>
        <template #afterSearch>
            <div class="flex items-center justify-start mx-2">
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer mr-2" @click="downloadData('xls')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">XLS</div>
                </div>
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer ml-2" @click="downloadData('csv')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">CSV</div>
                </div>
            </div>
        </template>
    </super-table>

    <super-table
        v-if="stats.ordersDataTable"
        id="cycles-orders-table"
        class="mt-4"
        :filters="ordersFilters"
        :pager="stats.ordersDataTable.pager"
        :columns="columnsOrdersTable"
        :records="stats.ordersDataTable.records"
        columnsStorageName="__columns_cycles_orders_table"
        type="cycles_orders_table"
        @getData="ordersGet"
        @applyFilters="changeFilter2">
        <template #tableTitle>
            <div class="text-base text-left mb-2 text-gray-600 dark:text-white/75">
                {{ stats.localization['trade_statistics_management_ordershistory_title'] + ` ${stats.currentCycle}` }}
            </div>
        </template>
        <template #afterLabel>
            <rb-checkbox
                :label="stats.localization['trade_statistics_management_ordershistory_show_orders_for_all_cycles_checkbox']"
                v-model:checked="stats.forAllCycles" />
        </template>
        <template #stats>
            <n-card
                size="small"
                :bordered="false"
                class="shadow bg-white dark:bg-darkCardBg rounded-lg my-3"
                :segmented="{
                    content: true,
                    footer: 'soft'
                }">
                <div class="text-md text-gray-600 dark:text-white/75">
                    <span>{{ stats.localization['trade_statistics_management_ordershistory_total'] }}</span>
                    <n-tag round size="small" :bordered="false" type="warning" class="ml-2">
                        <span class="text-yellow-500 font-semibold">{{ stats.ordersDataTable.stats.total }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_bots_running'] }}</span>
                    <n-tag round size="small" :bordered="false" type="success" class="ml-2">
                        <span class="text-green-500 font-semibold">{{ stats.ordersDataTable.stats.running }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_management_ordershistory_canceled'] }}</span>
                    <n-tag round size="small" :bordered="false" type="error" class="ml-2">
                        <span class="font-semibold">{{ stats.ordersDataTable.stats.canceled }}</span>
                    </n-tag>
                    
                    <span class="ml-4">{{ stats.localization['trade_statistics_management_ordershistory_partcanceled'] }}</span>
                    <n-tag round size="small" :bordered="false" class="ml-2">
                        <span class="font-semibold">{{ stats.ordersDataTable.stats.part_canceled }}</span>
                    </n-tag>

                    <span class="ml-4">{{ stats.localization['trade_statistics_management_ordershistory_completed'] }}</span>
                    <n-tag round size="small" :bordered="false" type="info" class="ml-2">
                        <span class="text-blue-400 font-semibold">{{ stats.ordersDataTable.stats.completed }}</span>
                    </n-tag>
                </div>
            </n-card>
        </template>
        <template #afterSearch>
            <div class="flex items-center justify-start mx-2">
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer mr-2" @click="downloadData2('xls')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">XLS</div>
                </div>
                <div class="hover:text-main dark:hover:text-main text-gray-600 dark:text-white/75 cursor-pointer ml-2" @click="downloadData2('csv')">
                    <n-icon class="text-2xl">
                        <ClipboardDataBar24Regular />
                    </n-icon>
                    <div class="text-xs text-center -mt-2 font-bold">CSV</div>
                </div>
            </div>
        </template>
    </super-table>
</template>
<!-- <div v-if="stats.localization">

    <cycles-orders-table
        v-if="stats.ordersDataTable"
        :filters="ordersFilters"
        @getData="ordersGet($event)"
        @apply="changeOrdersFilters([$event.filter, $event.value], $event.update)" />


</div> -->

<base-dialog-desktop
    v-model:show="cancelOrders"
    @positive-click="onCancelOrders"
    @negative-click="cancelOrders = false">
    <div class="text-md" v-html="stats.localization['trade_statistics_management_cancelorders_button']"></div>
    <template #body>
        <n-divider />
        <rb-checkbox
            :label="stats.localization['trade_statistics__stop_bot_after_operation']"
            v-model:checked="stopBotAfterOperation" />
    </template>
</base-dialog-desktop>

<base-dialog-desktop
    v-model:show="showConfirmation"
    @positive-click="showConfirmation = false, onGeneralConfirmClicked(action)"
    @negative-click="showConfirmation = false">
    <div class="text-md" v-html="refs.localization.confirmations.bot[action]"></div>
</base-dialog-desktop>

<base-dialog-desktop
    v-model:show="markLastAsCanceled"
    @positive-click="doMarkLastAsCanceled"
    @negative-click="markLastAsCanceled = false">
    <div class="text-md" v-html="stats.localization['mark_last_cycle_canceled']"></div>
</base-dialog-desktop>

<base-modal-desktop
    v-model:show="showConfirmationCheck">
    <template #default>
        <div class="text-md text-gray-600 dark:text-white/75">{{ declineMessage }}</div>
    </template>
    <template #footer>
        <div class="flex items-center justify-end">
            <rb-checkbox
                :label="'- ' + confirmLabel"
                v-model:checked="genConfCheck" />

            <n-button
                strong
                class="ml-3 text-white/90 rounded-md"
                :color="gl.mainColor"
                :disabled="!genConfCheck"
                @click="showConfirmationCheck = false, onGeneralConfirmClicked('start')">
                {{ refs.localization.dialogs.ok }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// icon
import {
    ArrowLeft12Regular,
    ChevronLeft24Filled,
    ErrorCircle20Regular,
    ClipboardDataBar24Regular } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';
import RbSwitch from '@components/rb-switch';
import RbCheckbox from '@components/rb-checkbox';
import FilterBots from '@components/filters/bots';
import ResetFixOrder from '@components/stats-management/reset-fix-order/desktop.vue';
import BaseModal from '@components/base/base-modal';
import FilterStates from '@components/filters/states';
import FilterStatuses from '@components/filters/statuses';
import SuperTable from '@components/super-table/desktop.vue';
import FilterExchanges from '@components/filters/exchanges';
import InsuranceOrder from '@components/stats-management/insurance-order/desktop.vue';

// UI
import {
    NTag,
    NIcon,
    NCard,
    NTabs,
    NModal,
    NButton,
    NTabPane,
    NDivider,
    NSkeleton,
    NScrollbar,
    NDatePicker } from 'naive-ui';

export default {
    name: 'managment',
    components: {
        NTag,
        NIcon,
        NCard,
        NTabs,
        NModal,
        RbCoin,
        NButton,
        NDivider,
        RbSwitch,
        NTabPane,
        SuperTable,
        BaseModal,
        NSkeleton,
        RbCheckbox,
        NScrollbar,
        FilterBots,
        NDatePicker,
        FilterStates,
        ResetFixOrder,
        InsuranceOrder,
        FilterStatuses,
        FilterExchanges,
        ArrowLeft12Regular,
        ChevronLeft24Filled,
        ErrorCircle20Regular,
        ClipboardDataBar24Regular,
    },
    setup(props, context) {
        
        return {
            ...general(arguments),
        };
    },
};
</script>